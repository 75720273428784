import { FC } from "react"
import React from "reactn"
import { graphql } from "gatsby"
import marked from "marked"
import PageHeader from "../components/PageHeader"
import { ImgProps } from "../components/Img"
import Link from "../components/Link"
import Meta, { MetaProps } from "../components/Meta"

interface EntreprisePageTemplateProps {
  img: ImgProps,
  title: string,
  text: string,
  link: {
    href: string,
    text: string
  }
}

const EntreprisePageTemplate: FC<EntreprisePageTemplateProps> = ({
  img,
  title,
  text,
  link
}) => {
  return (
    <main id="index-page">
      <section
        id="info"
        className="bg-cover bg-center"
        style={{
          backgroundImage: `url("${img.src}")`,
          minHeight: "66vh"
        }}
      >
        <div
          className="h-full w-full flex flex-col items-center justify-center px-6 py-12 text-center"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            minHeight: "inherit"
          }}
        >
          <h1 className="title text-white">
            {title}
          </h1>

          <div
            className="my-12 markdown max-w-2xl text-white mx-auto w-full lg:my-8"
            dangerouslySetInnerHTML={{
              __html: marked(text)
            }}
          />

          <Link
            to={link.href}
            className="btn-ghost light mt-6"
          >
            {link.text}
          </Link>
        </div>
      </section>
    </main>
  )
}

interface EntreprisePageProps {
  data: {
    markdownRemark: {
      frontmatter: EntreprisePageTemplateProps & {
        meta: MetaProps
      }
    }
  }
}

const EntreprisePage: FC<EntreprisePageProps> = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <>
      <Meta {...frontmatter.meta} />

      <PageHeader />

      <EntreprisePageTemplate
        img={frontmatter.img}
        title={frontmatter.title}
        text={frontmatter.text}
        link={frontmatter.link}
      />
    </>
  )
}

export default EntreprisePage

export const EntreprisePageQuery = graphql`
  query EntreprisePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {

        meta {
          title
          description
          css
          js
        }

        title
        text
        img {
          src
          alt
        }
        link {
          href
          text
        }

      }
    }
  }
`
